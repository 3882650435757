@import 'styles/layers.scss';

@layer component {
  .btn-added {
    width: 34px;
    height: 34px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  .btns {
    column-gap: 6px;
  }

  .quick-deploy {
    height: 25px;
    padding: 0;
    font-size: 12px;
    width: 71px;

    &__icon {
      margin-right: 3px;
    }
  }
}